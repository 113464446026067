<bit-callout type="info" *ngIf="showKeyConnectorInfo">
  {{ "keyConnectorPolicyRestriction" | i18n }}
</bit-callout>

<div [formGroup]="data">
  <bit-form-control>
    <input type="checkbox" bitCheckbox [formControl]="enabled" id="enabled" />
    <bit-label>{{ "turnOn" | i18n }}</bit-label>
  </bit-form-control>

  <bit-form-control>
    <input type="checkbox" bitCheckbox formControlName="enforceOnLogin" id="enforceOnLogin" />
    <bit-label>{{ "enforceOnLoginDesc" | i18n }}</bit-label>
  </bit-form-control>

  <div class="tw-flex tw-space-x-4">
    <bit-form-field class="tw-flex-auto">
      <bit-label>{{ "minComplexityScore" | i18n }}</bit-label>
      <bit-select formControlName="minComplexity" id="minComplexity">
        <bit-option
          *ngFor="let o of passwordScores"
          [value]="o.value"
          [label]="o.name"
        ></bit-option>
      </bit-select>
    </bit-form-field>
    <bit-form-field class="tw-flex-auto">
      <bit-label>{{ "minLength" | i18n }}</bit-label>
      <input
        bitInput
        type="number"
        formControlName="minLength"
        id="minLength"
        [min]="MinPasswordLength"
      />
    </bit-form-field>
  </div>

  <bit-form-control class="!tw-mb-2">
    <input type="checkbox" bitCheckbox formControlName="requireUpper" id="requireUpper" />
    <bit-label>A-Z</bit-label>
  </bit-form-control>
  <bit-form-control class="!tw-mb-2">
    <input type="checkbox" bitCheckbox formControlName="requireLower" id="requireLower" />
    <bit-label>a-z</bit-label>
  </bit-form-control>
  <bit-form-control class="!tw-mb-2">
    <input type="checkbox" bitCheckbox formControlName="requireNumbers" id="requireNumbers" />
    <bit-label>0-9</bit-label>
  </bit-form-control>
  <bit-form-control>
    <input type="checkbox" bitCheckbox formControlName="requireSpecial" id="requireSpecial" />
    <bit-label>!@#$%^&amp;*</bit-label>
  </bit-form-control>
</div>
