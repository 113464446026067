<bit-section>
  <bit-section-header>
    <h2 bitTypography="h6">{{ "additionalInformation" | i18n }}</h2>
  </bit-section-header>
  <bit-card>
    <label class="tw-text-xs tw-text-muted tw-select-none">
      {{ "note" | i18n }}
    </label>
    <div class="tw-flex tw-justify-between">
      <textarea readonly bitInput aria-readonly="true">{{ notes }}</textarea>
      <button
        bitIconButton="bwi-clone"
        size="small"
        type="button"
        [appCopyClick]="notes"
        showToast
        [appA11yTitle]="'copyValue' | i18n"
      ></button>
    </div>
  </bit-card>
</bit-section>
